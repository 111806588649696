/* src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Custom styles */

.border-neon {
  border-image: linear-gradient(
    45deg,
    #00eaff,
    #00eaff,
    #00eaff,
    #00eaff,
    #00eaff
  ) 1;
}

.animate-glow {
  animation: glow 2s infinite alternate;
}

@keyframes glow {
  from {
    box-shadow: 0 0 10px #00eaff, 0 0 20px #00eaff, 0 0 30px #00eaff;
  }
  to {
    box-shadow: 0 0 20px #00eaff, 0 0 30px #00eaff, 0 0 40px #00eaff;
  }
}

/* Custom scrollbar */
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #1a1a1a;
}

::-webkit-scrollbar-thumb {
  background: #555;
}

::-webkit-scrollbar-thumb:hover {
  background: #888;
}

/* Custom scrollbar styles */
.custom-scrollbar::-webkit-scrollbar {
  width: 8px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #2d3748; /* Tailwind's gray-800 */
  border-radius: 4px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #00ff85; /* gamefi-green */
  border-radius: 4px;
  border: 2px solid #2d3748; /* Match the track's background */
}

/* Firefox */
.custom-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #00ff85 #2d3748;
}
