/* src/components/SlotMachine.css */
.slot-machine {
  width: 100%;
  max-width: 400px; /* Adjust as needed */
  height: 250px; /* Should match itemHeight * number of visible items */
  overflow: hidden;
  border: 5px solid #ff00e5; /* gamefi-pink */
  border-radius: 20px;
  background-color: #1a202c; /* Tailwind's bg-gray-800 */
  position: relative;
  box-sizing: border-box; /* Include border in width calculations */
}

.slot-machine::before,
.slot-machine::after {
  content: '';
  position: absolute;
  left: 0;
  width: 100%;
  height: 25%; /* Adjust as needed */
  pointer-events: none;
}

.slot-machine::before {
  top: 0;
  background: linear-gradient(to bottom, rgba(26, 32, 44, 1), rgba(26, 32, 44, 0));
}

.slot-machine::after {
  bottom: 0;
  background: linear-gradient(to top, rgba(26, 32, 44, 1), rgba(26, 32, 44, 0));
}

.slot-machine-scroll {
  position: absolute;
  width: 100%;
}

.slot-machine-item {
  width: 100%;
  height: 50px; /* Should match itemHeight */
  line-height: 50px; /* Should match itemHeight */
  text-align: center;
  font-size: 24px;
  color: #ffffff;
  border-bottom: 1px solid #ffffff; /* Add a white line between items */
}

.winner-item {
  background-color: #ff00e5; /* gamefi-pink */
  color: #ffffff;
  font-weight: bold;
}

.swal2-title-custom {
  font-size: 24px;
}

.slot-machine-image-overlay {
  position: absolute;
  top: 50px; /* Adjust as needed to position the image */
  left: 35%;
  transform: translateX(-100%);
  z-index: 10; /* Ensure the image is above the slot machine content */
}